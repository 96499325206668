import { Drawer, Space } from "antd"
import React, { useState } from "react"
import { Link} from "gatsby"
import Img from "gatsby-image"

import MainContent from "./MainContent"
import KnowUs from "./KnowUs"
import CloseIcon from "../images/Rectangle86.png"
import arrow from "../images/arrow.png"
import HowWeHelp from "./HowWeHelp"
import OurPrimaryFocus from "./OurPrimaryFocus"
import InformationHub from "./InformationHub"

const MemuContainer = ({ currentTab, setCurrentTab, data, queryData }) => {
  return (
    <div class="grid-container">
      <div class="first-div">
        <div className="sidebar">
          <div
            onClick={() => {
              setCurrentTab("know-us")
            }}
            className={`sidebar-a ${currentTab === "know-us" ? "active" : ""}`}
          >
            Know Us
          </div>
          <div
            onClick={() => {
              setCurrentTab("How-We-Help")
            }}
            className={`sidebar-a ${currentTab === "How-We-Help" ? "active" : ""
              }`}
          >
            How We Help
          </div>
          <div
            onClick={() => {
              setCurrentTab("our-primary-focus")
            }}
            className={`sidebar-a ${currentTab === "our-primary-focus" ? "active" : ""
              }`}
          >
            Our Primary Focus
          </div>
          <div
            onClick={() => {
              setCurrentTab("information-hub")
            }}
            className={`sidebar-a ${currentTab === "information-hub" ? "active" : ""
              }`}
          >
            Information Hub
          </div>
        </div>
      </div>
      <div className="second-div">
        {currentTab === "" && <MainContent queryData={queryData} />}
        {currentTab === "know-us" && <KnowUs />}
        {currentTab === "How-We-Help" && <HowWeHelp />}
        {currentTab === "our-primary-focus" && (
          <OurPrimaryFocus queryData={queryData} />
        )}
        {currentTab === "information-hub" && (
          <InformationHub queryData={queryData} />
        )}
      </div>
    </div>
  )
}

const MenuNew = ({ isOpen, onClose, data, queryData }) => {
  const [currentTab, setCurrentTab] = useState("")
  const emptyCurrentTab = () => {
    setCurrentTab("")
  }
  return (
    <Drawer
      className="menu-drawer"
      title={
        <div className="Drawer-Header">
          <Img
            fluid={queryData.VMLogoBlack.childImageSharp.fluid}
            alt="Visiomate Logo"
            className="log-img"
          />
          <div>
            <button
              style={{
                background: "#EDEDED",
                color: "#000000",
                border: "none",
                fontSize: "16px",
                fontWeight: "600",
                padding: "0",
              }}
              className="buttonHover"
              onClick={() => {
                emptyCurrentTab()
                onClose()
              }}
            >
              Close
            </button>
            <button
              style={{
                background: "#EDEDED",
                color: "#000000",
                border: "none",
                paddingLeft: "12px",
                paddingRight: "0px",
              }}
              className="buttonHover"
              title="close"
              onClick={() => {
                emptyCurrentTab()
                onClose()
              }}
            >
              <img
                src={CloseIcon}
                style={{
                  width: "25px",
                  marginLeft: "0px ",
                  paddingRight: "0px",
                  marginBottom: "0px",
                  marginTop: "0",
                }}
                alt="Closeicon"
              />
            </button>
          </div>
        </div>
      }
      placement={"top"}
      width="100vw"
      onClose={onClose}
      visible={isOpen}
      headerStyle={{ background: "#EDEDED", paddingLeft: "6px" }}
      drawerStyle={{
        background: "#EDEDED",
        paddingLeft: "3%",
        paddingRight: "3%",
        paddingBottom: "2%",
        paddingTop: "0%",
      }}
      closable={false}
      height="100vh"
      footer={
        <Space style={{ display: "flex", justifyContent: "space-between" }}>
          <div className="LinksHover">
              <Link to="https://www.linkedin.com/company/visiomate" target="_blank"><Img fluid={queryData.linkedin.childImageSharp.fluid} /></Link>
              <Link to="https://www.facebook.com/Visiomate/" target="_blank"><Img fluid={queryData.facebook.childImageSharp.fluid} /></Link>
              {/* <Link to="https://www.linkedin.com/company/visiomate" target="_blank"><Img fluid={queryData.twitter.childImageSharp.fluid} /></Link> */}
              <Link to="https://www.instagram.com/visiomate_official" target="_blank"><Img fluid={queryData.instagram.childImageSharp.fluid} /></Link>
              <Link to="https://www.youtube.com/@visiomate" target="_blank"><Img fluid={queryData.youtube.childImageSharp.fluid} className={"youtubeIcon"}/></Link>
          </div>
          <div>
            <Link to="/contact">
              <button
                style={{
                  background: "#0D1B67",
                  color: "#FFFFFF",
                  border: "none",
                  width: "173px",
                  height: "41px",
                  fontSize: "16px",
                }}
                className="buttonHover"
              >
                Let's Connect
                <img
                  src={arrow}
                  style={{
                    width: "25px",
                    marginLeft: "12px ",
                    marginBottom: "3px",
                  }}
                  alt="Connect button"
                />
              </button>
            </Link>
          </div>
        </Space>
      }
    >
      <MemuContainer
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        queryData={queryData}
      />
    </Drawer>
  )
}

export default MenuNew
