import React from "react"
import { Layout as AntLayout } from "antd"
import {
  RecoilRoot,
} from 'recoil';
import Header from "./Header"
import Footer from "./Footer"
import TalkToUs from '../components/Talktous'
import "antd/dist/antd.css"
import "../styles/layout.scss"
import { Helmet } from 'react-helmet';
import { useLocation } from '@reach/router';
const Layout = ({ children, className, data, style,pageTitle,descrip }) => {
  const { Content } = AntLayout
  const location = useLocation();
  return (
    <RecoilRoot>
      <AntLayout >
      <title>{pageTitle}</title>
      <meta name="description" content={descrip} />
      <Helmet>
      <link rel="canonical" href={`${location.href}`} key="canonical-link" />
      </Helmet>
        <Header data={data} />
        <Content className={className} style={style} >
          {children}
        </Content>
        <TalkToUs />
        <Footer data={data} />
      </AntLayout>
    </RecoilRoot>
  )
}

export default Layout
