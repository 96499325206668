import React from 'react'
import { Link } from "gatsby"
import Img from 'gatsby-image'
const OurPrimaryFocus = ({ queryData }) => {
    return (
        <div className='OurPrimaryFocus'>
            <div className='OurPrimaryFocus-grid'>
                <div>
                    <h1>Industries</h1>
                    <Link><p>Retail</p></Link>
                    <Link><p>Manufacturing</p></Link>
                    <Link><p>Distribution</p></Link>
                    <Link><p>Education</p></Link>
                </div>
                <div>
                    <h1>Case Sudies</h1>
                    <Link to="/caseStudies/projects/ORR"><p>ORR Protection Teaming Up</p></Link>
                    <Link to="/caseStudies/projects/muscatMGHRSolution"><p>Muscat Media Group HR Solution</p></Link>
                    <Link to="/caseStudies/projects/sOHARPortandFCPortal"><p>SOHAR Port and Freezone Container Portal </p></Link>
                    <Link to="/caseStudies/projects/cakesBakesDAX2012Revamp"><p>Cakes & Bakes Dynamics AX 2012 Revamp </p></Link>
                    <Link to="/caseStudies/projects/banuMukhtarCRM"><p>Banu Mukhtar CRM implementation</p></Link>
                    <Link to="/caseStudies/projects/miaGroup"><p>MIA Group Dynamics AX 2012 Revamp</p></Link>
                </div>
            </div>
            <div className='devHide'>
                <h1 className=''>What’s Latest</h1>
                <div className='divSize'>
                    <Img fluid={queryData.viztosForMenu.childImageSharp.fluid} alt="viztosForMenu" />
                </div>
                <p className='divSize-p'>Viztos -  is a custom made POS based on Odoo that eliminates the common limitations in any other POS software businesses face. </p>
            </div>
        </div >
    )
}

export default OurPrimaryFocus
