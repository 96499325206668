import React, { useState } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import ReCAPTCHA from "react-google-recaptcha"
import { Form, Input, Layout, Typography, message, Modal, Spin } from "antd"
import axios from "axios"
import "../styles/footer.scss"
const Footer = ({ data }) => {
  const [form] = Form.useForm()
  const [captchaValue, setCaptchaValue] = useState(null)
  const [loading, setLoading] = useState(false)
  let modal
  const countDown = () => {
    modal = Modal.success(
      {
        title: <span onClick={() => destroyForModal()}>X</span>,
        content: <ReCAPTCHA
          sitekey="6Le4Xc4pAAAAACdoP1-v8vNKtHOYfkJiXrOBvi8V"
          onChange={onCaptchaChange}
          className="recaptcha"
        />,
        className: 'modal-for-footer'
      }

    );
    const destroyForModal = () => {
      modal.destroy();
    }
  };
  const { Footer } = Layout

  const onFinish = (values) => {
    handleFormData(values)

    form.resetFields()
  };
  const onCaptchaChange = value => {
    setCaptchaValue(value)
    modal.destroy();
    form.submit()
  }
  const handleFormData = async values => {
    const {
      name,
      email,
    } = values;
    const formData = {
      name,
      email,
    }

    sendFormData(formData)

  }
  const sendFormData = async (data) => {
    setLoading(true)
    try {
      const response = await axios.post('https://staging.visiomate.com/newsletter', data);
      setLoading(false)
      form.resetFields()
      message.success('Mail Send successfully!');
    } catch (error) {
      console.error('Error:', error);
      message.error('Failed to submit application. Please try again later.');
    }
  };
  const queryData = useStaticQuery(graphql`
  query FooterQuery {
    VMLogoBlack: file(relativePath: { eq: "logo_black_LST.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    VMLogoWhite: file(relativePath: { eq: "logo_white_LST.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    UMTVisiomate: file(relativePath: { eq: "lifeatvisiomate/UMT_Visiomate3.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    umrahshuttleapp: file(relativePath: { eq: "mobile/umrahshuttleapp.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    viztosForMenu: file(relativePath: { eq: "news/viztos.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    learningcircle: file(relativePath: { eq: "news/Circle.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    CloseIcon: file(relativePath: { eq: "Rectangle86.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    instagram: file(relativePath: { eq: "5335781_camera_instagram_social media_instagram logo_icon.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    facebook: file(relativePath: { eq: "5305153_fb_facebook_facebook logo_icon.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    linkedin: file(relativePath: { eq: "367593_linkedin_business_network_social_icon.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    twitter: file(relativePath: { eq: "1298770_twitter_bird_social media_tweet_icon.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    youtube: file(relativePath: { eq: "5305165_youtube_youtube logo_icon.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    cloud: file(relativePath: { eq: "11030166_upload_cloud_icon 2.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

  }
`)
  return (
    <Footer className="vm-footer-wrapper">
      <div>
        <div className="img">
          <Link to="/">
            <Img fluid={queryData.VMLogoWhite.childImageSharp.fluid} />
          </Link>
        </div>


        <div className="vm-footer-grid">
          <div className="first-div">
            <p>
              Partnering with visionary businesses since 2018, we develop and
              expand, always with customer satisfaction in mind.
            </p>
            <div className="link">
              <Link to="/about">About us</Link>
              <Link to="/lifeAtVisiomate">Life at Visiomate</Link>
              <Link to="/career">Careers</Link>
              <Link to="/NEWS">News</Link>
              {/* <Link to="/Blogs/?cat=all">Blogs</Link> */}
              {/* <Link to="/WebinarEvents">Webinar & Events</Link> */}
              <Link to="/WhitepapereBooks">Whitepaper & eBooks</Link>
            </div>
          </div>
          <div className="second-div">
            <div className="second-grid1">
              <div>
                <h1>Software Development</h1>

                <Link to="/services/webdevelopment">
                  <p>Web based Solution</p>
                </Link>
                <Link to="/services/mobiledevelopment">
                  <p>Mobile Application</p>
                </Link>
                <Link to="/services/staffaugmentation">

                  <p>Staff Augmentation</p>
                </Link>
                <Link to="/services/softwaretesting">
                  <p>Quality Assurance</p>
                </Link>
                <Link to="/services/wordpress_website_developement">
                  <p>WordPress Websites</p>
                </Link>
              </div>
              <div>
                <h1>Microsoft Dynamics 365</h1>
                <Link to="/dynamics365/financeOperations">
                  <p>Finance & Operations</p>
                </Link>
                <Link to="/dynamics365/upgrade">
                  <p>Upgrade</p>
                </Link>
                <Link to="/dynamics365/optimization">
                  <p>Optimization</p>
                </Link>
                <Link to="/dynamics365/support">
                  <p>Support</p>
                </Link>
                <Link to="/dynamics365/integration">
                  <p>Integration</p>
                </Link>
              </div>
            </div>
            <div className="second-grid2">
              <div>
                <h1>Industries</h1>
                <p>Retail</p>
                <p>Manufacturing</p>
                <p>Distribution</p>
                <p>Education</p>
              </div>
              <div>
                <h1>Odoo</h1>
                <Link to="/Odoo/implementationPartne">
                  <p>Implementation Partner</p>
                </Link>
                <Link to="/Odoo/upgrade">
                  <p>Upgrade</p>
                </Link>
                <Link to="/Odoo/optimization">
                  <p>Optimization</p>
                </Link>
                <Link to="/Odoo/support">
                  <p>Support</p>
                </Link>
                <Link to="/Odoo/integration">
                  <p>Integration</p>
                </Link>
              </div>
            </div>
            <div className="second-grid3">
              <div>
                <h1>Solutions</h1>
                <Link to="/caseStudies/products/donationCampaignManagement">
                  <p>Donation Campaign Management</p>
                </Link>
                <Link to="/caseStudies/products/visioedge">
                  <p>Visioedge - ERP Solution </p>
                </Link>
                <Link to="/caseStudies/products/viztos">
                  <p>Viztos - Cafe / Oil Lubricant Management</p>
                </Link>

                <Link to="/caseStudies/products/mD365BulkStockDistribution">
                  <p>Microsoft Dynamics 365 Bulk Stock Distribution</p>
                </Link>
                <Link to="/caseStudies/products/micD365XstakIntegration">
                  <p>Microsoft Dynamics 365 Xstak Integration </p>
                </Link>

                <Link to="/caseStudies/products/classManagement">

                  <p>Class Management</p>
                </Link>
              </div>
              <div>
                <h1>Case Studies</h1>
                <Link to="/caseStudies/projects/ORR">
                  <p>ORR Protection Teaming Up</p>
                </Link>
                <Link to="/caseStudies/projects/muscatMGHRSolution">
                  <p>Muscat Media Group HR Solution</p>
                </Link>
                <Link to="/caseStudies/projects/sOHARPortandFCPortal">
                  <p>SOHAR Port and Freezone Container Portal </p>
                </Link>
                <Link to="/caseStudies/projects/cakesBakesDAX2012Revamp">
                  <p>Cakes & Bakes Dynamics AX 2012 Revamp </p>
                </Link>
                <Link to="/caseStudies/projects/banuMukhtarCRM">
                  <p>Banu Mukhtar CRM implementation</p>
                </Link>
                <Link to="/caseStudies/projects/miaGroup">
                  <p>MIA Group Dynamics AX 2012 Revamp</p>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="vm-footer">
        <div className="socail-icons">
        <Link to="https://www.linkedin.com/company/visiomate" target="_blank"><Img fluid={queryData.linkedin.childImageSharp.fluid} /></Link>
        <Link to="https://www.facebook.com/Visiomate/" target="_blank"><Img fluid={queryData.facebook.childImageSharp.fluid} /></Link>
        {/* <Link to="https://www.linkedin.com/company/visiomate" target="_blank"><Img fluid={queryData.twitter.childImageSharp.fluid} /></Link> */}
        <Link to="https://www.instagram.com/visiomate_official" target="_blank"><Img fluid={queryData.instagram.childImageSharp.fluid} /></Link>
        <Link to="https://www.youtube.com/@visiomate" target="_blank"><Img fluid={queryData.youtube.childImageSharp.fluid} /></Link>
        </div>
        <div className="form-div">

          <Form className="form" form={form} onFinish={onFinish}>
            <p>Let us know that we can share knowldge</p>
            <Form.Item
              label=""
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please input your Name!",
                },
              ]}
            >
              <Input placeholder="Name" />
            </Form.Item>
            <Form.Item
              label=""
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please input your Email!",
                },
                {
                  pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                  message: 'Please enter a valid email address!',
                },
              ]}
            >
              <Input placeholder="Email" type="email" />
            </Form.Item>
          </Form>
          <div className="img-bg"
            onClick={countDown} >
            {loading ? <Spin /> : <Img fluid={queryData.cloud.childImageSharp.fluid} />}

          </div>
        </div>
        <div className="pbv">
          Copyright ©2024 All rights reserved | Powered By Visiomate
        </div>
      </div>
      {/* <div className="vm-footer-for-tablet">
        <div className="form-div">

          <Form className="form" form={form} onFinish={onFinish}>
            <p>Let us know that we can share knowldge</p>
            <Form.Item
              label=""
              name="name"

              rules={[
                {
                  required: true,
                  message: "Please input your Name!",
                },
              ]}
            >
              <Input placeholder="Name" />
            </Form.Item>
            <Form.Item
              label=""
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please input your Email!",
                },
              ]}
            >
              <Input placeholder="Email" type="email" />
            </Form.Item>
          </Form>
          <div className="img-bg"
            onClick={countDown} >
            {loading ? <Spin /> : <Img fluid={queryData.cloud.childImageSharp.fluid} />}
          </div>
        </div>
        <div>
           <div className="socail-icons">
              <Link to="https://www.linkedin.com/company/visiomate" target="_blank"><Img fluid={queryData.linkedin.childImageSharp.fluid} /></Link>
              <Link to="https://www.facebook.com/Visiomate/" target="_blank"><Img fluid={queryData.facebook.childImageSharp.fluid} /></Link>
              <Link to="https://www.instagram.com/visiomate_official" target="_blank"><Img fluid={queryData.instagram.childImageSharp.fluid} /></Link>
              <Link to="https://www.youtube.com/@visiomate" target="_blank"><Img fluid={queryData.youtube.childImageSharp.fluid} /></Link>
            </div>
          <div className="pbv">
            Copyright ©2024 All rights reserved | Powered By Visiomate
          </div>
        </div>
      </div> */}
    </Footer>
  )
}

export default Footer
