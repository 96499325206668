import React from "react"
import { Link } from "gatsby"
const HowWeHelp = () => {
    return (
        <>
            <div className="how-we-help">
                <div>
                    <div className="first">
                        <div>
                            <h1>Services</h1>
                            <Link to="/services/webdevelopment">
                                <p>Web based Solution</p>
                            </Link>
                            <Link to="/services/mobiledevelopment">
                                <p>Mobile Application</p>
                            </Link>
                            <Link to="/services/staffaugmentation">
                                <p>Staff Augmentation</p>
                            </Link>
                            <Link to="/services/softwaretesting">
                                <p>Quality Assurance</p>
                            </Link>
                            <Link to="/services/wordpress_website_developement">
                                <p>WordPress Websites</p>
                            </Link>
                        </div>
                        <div>
                            <h1>Microsoft Dynamics 365</h1>
                            <Link to="/dynamics365/financeOperations">
                                <p>Finance & Operations</p>
                            </Link>
                            <Link to="/dynamics365/upgrade">
                                <p>Upgrade</p>
                            </Link>
                            <Link to="/dynamics365/optimization">
                                <p>Optimization</p>
                            </Link>
                            <Link to="/dynamics365/support">
                                <p>Support</p>
                            </Link>
                            <Link to="/dynamics365/integration">
                                <p>Integration</p>
                            </Link>
                        </div>
                    </div>
                    <div className="second">
                        <div>
                            <h1>Odoo</h1>
                            <Link to="/Odoo/implementationPartne">
                                <p>Implementation Partner</p>
                            </Link>
                            <Link to="/Odoo/upgrade">
                                <p>Upgrade</p>
                            </Link>
                            <Link to="/Odoo/optimization">
                                <p>Optimization</p>
                            </Link>
                            <Link to="/Odoo/support">
                                <p>Support</p>
                            </Link>
                        </div>
                        <div>
                            <h1>Solutions</h1>
                            <Link to="/caseStudies/products/visioedge">
                                <p>Visioedge - ERP Solution </p>
                            </Link>
                            <Link to="/caseStudies/products/viztos">
                                <p>Viztos - Cafe / Oil Lubricant Management</p>
                            </Link>
                            <Link to="/caseStudies/products/mD365BulkStockDistribution">
                                <p>Microsoft Dynamics 365 Bulk Stock Distribution</p>
                            </Link>
                            <Link to="/caseStudies/products/classManagement">
                                <p>Class Management Solution</p>
                            </Link>
                            <Link to="/caseStudies/products/donationCampaignManagement">
                                <p>Donation Campaign Management</p>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HowWeHelp
